<template>
	<template-base>
		<div class="text-center">
			<h1>Você não tem permissão para acessar o MMI Injetoras.</h1>
			<h2>Por favor, entre em contato com o administrador.</h2>
		</div>
		<section>
			<LockIcon size="10x" class="text-dark"/>
		</section>
		<div class="d-flex justify-content-between mt-4">
			<router-link
				:to="$route.params.prev || '/'"
				class="btn btn-secondary mr-2"
				>Voltar</router-link
			>
			<div>
				<button class="btn btn-success text-white lg" @click="logout()">
					Trocar usuário
				</button>
			</div>

		</div>
	</template-base>
</template>

<style scoped>
	section {
		height: 30vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>

<script>
	import TemplateBase from "../templates/Base";
	import { AuthService } from "../services/auth";
	import router from "../router";
	import { encontraTelaComPermissao } from "@/helpers/permissions";

	export default {
		components: { TemplateBase },

		data () {
			return {
				authService: new AuthService()
			};
		},

		methods: {
			logout () {
				this.authService.logout();
			}
		},

		mounted () {
			// Traz as permissões inerentes ao usuário
			let userPermissions = [];
			if (localStorage.params)
				userPermissions = (JSON.parse(localStorage.params).grupoUsuario).split(",");

			const result = encontraTelaComPermissao(userPermissions, router.options.routes);
			if (result !== "403") {
				router.push({ name: result }).catch(e => {
					if (e.type != 8) console.log(e);
				});
			}
		}
	};
</script>
